import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import get_your_own_custom_template from '../../../../../../../common/src/assets/image/get_your_own_custom_template.png';
import custom_template_in_template_list from '../../../../../../../common/src/assets/image/custom_template_in_template_list.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal includes{' '}
        <Link to="/Documentation/Create-Calendar/Templates-and-Options">
          many templates
        </Link>{' '}
        that you can{' '}
        <Link to="/Documentation/How-To/Customize-a-Template">
          customize yourself
        </Link>
        . However, if you need a different type of template layout or something
        more advanced, that's where we can help! We include a self-promotional
        plug below the template list to let you know that we offer this service.
        If you don't want to see this, click the header to collapse it. It will
        stay collapsed.
      </p>
      <p>
        <Image
          alt=""
          src={get_your_own_custom_template}
          style={{ width: 450 }}
        />
      </p>
      <p>
        If you'd like to get the process started, click the{' '}
        <strong>Get Started</strong> button and let us know what you're looking
        for or <Link to="/#contact_us_section">contact us</Link> here.
      </p>
      <p>
        After we develop your custom template, you'll receive instructions on
        how to add it to PrintableCal (it's really easy). Your template will
        then appear in the template list and can be used just like any of the
        other templates that are included with PrintableCal.
      </p>
      <p>
        <Image
          alt=""
          src={custom_template_in_template_list}
          style={{ width: 450 }}
        />
      </p>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Get-Your-Own-Custom-Template"
      commentsId="commentsplus_post_1196_489"
      title="Get Your Own Custom Template"
      description="You can get your own custom calendar template developed by the creators of PrintableCal."
      keywords="custom calendar template, professional calendar template"
      content={content}
    />
  );
};

export default Documentation;
